.app {
  text-align: center;
}

.app-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.instructions {
  max-width:600px;
  text-align:left;
  margin: auto;
  padding: 40px 30px;
}

@font-face {
  font-family: "Benjamin Franklin";
  src: url("BenjaminFranklin.ttf");
}



main {
  background: linear-gradient(#454545, #676767, #454545);
  display: grid;
  width: 100%;
}

.human_dice .die-item {

}




* {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
.dice {
  align-items: center;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-template-rows: auto;
  justify-items: center;
  padding: 2rem;
  perspective: 600px;
  height:15rem;
  width:48rem;
  margin:auto;
}

.dice button {
  position: absolute;
  bottom: 1rem;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.25);
  background-color:#cfcfcf;
  padding:10px;
  border-radius:5px;
  border-right:2px solid #6f6f6f;
  border-bottom:2px solid #6f6f6f;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  cursor: pointer;
  font-weight: bold;
}


.die-list {
  height: 5rem;
  list-style-type: none;
  position: absolute;
  transform-style: preserve-3d;
  width: 5rem;
  line-height: 7rem;
  text-align:center;
}

.even-roll.position0 {
  left: 4rem;
  top: 5rem;
}
.even-roll.position1 {
  left: 17rem;
  top: 4rem;
}
.even-roll.position2 {
  left: 30rem;
  top: 5rem;
}
.even-roll.position3 {
  left: 43rem;
  top: 6rem;
}

.odd-roll.position0 {
  left: 5rem;
  top: 6rem;
}
.odd-roll.position1 {
  left: 18rem;
  top: 5rem;
}
.odd-roll.position2 {
  left: 31rem;
  top: 6rem;
}
.odd-roll.position3 {
  left: 44rem;
  top: 5rem;
}
.even-roll {
  transition-property: left top transform;
  transition-duration: 1.5s;
  transition-timing-function: ease-out;
}
.odd-roll {
  transition-property: left top transform;
  transition-duration: 1.25s;
  transition-timing-function: ease-out;
}
.die-item {
  background-color: #fefefe;
  box-shadow: inset -0.35rem 0.35rem 0.75rem rgba(0, 0, 0, 0.3),
    inset 0.5rem -0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 100%;
  padding: 1rem;
  position: absolute;
  width: 100%;
  font-size:1.8rem;
  text-transform:uppercase;
  cursor: pointer;
}

.human-dice .die-item {
  background-color: #0F8ECF;
}
.elf-dice .die-item {
  background-color: #1B943D;
}
.dwarf-dice .die-item {
  background-color: #E40C11;
}
.halfling-dice .die-item {
  background-color: #EAD70B;
}
.goblin-dice .die-item {
  background-color: #999999;
}
.dragon-dice .die-item {
  background-color: #CB5410;
}
.face {
  position:absolute;
  left:0px;
  top:0px;
  right:0px;
  bottom:0px;
  text-align:center;
  width:100%;
  transform:rotate(45deg);
  font-family: 'Benjamin Franklin', serif;
  text-shadow:2px 2px 2px rgba(255, 255, 255, 0.25);
}

.face .icon {
  position: absolute;
  width:80%;
  height:50%;
  left:calc(10% + .15rem);
  margin: -.3rem;
  z-index:2;
  background-size: contain;
  background-repeat: no-repeat;
}

.face .icon-etch {
  z-index: 1;
}

.face .icon-top {
  top: 0px;
}

.face .icon.human {
  background-image: url("./human_icon.png");
}
.face .icon-etch.human {
  background-image: url("./human_icon_transparent.png");
}

.face .icon.elf {
  background-image: url("./elf_icon.png");
}
.face .icon-etch.elf {
  background-image: url("./elf_icon_transparent.png");
}

.face .icon.halfling {
  background-image: url("./halfling_icon.png");
}
.face .icon-etch.halfling {
  background-image: url("./halfling_icon_transparent.png");
}

.face .icon.dwarf {
  background-image: url("./dwarf_icon.png");
}
.face .icon-etch.dwarf {
  background-image: url("./dwarf_icon_transparent.png");
}

.face .icon.goblin {
  background-image: url("./goblin_icon.png");
}
.face .icon-etch.goblin {
  background-image: url("./goblin_icon_transparent.png");
}

.face .icon.dragon {
  background-image: url("./dragon_icon.png");
}
.face .icon-etch.dragon {
  background-image: url("./dragon_icon_transparent.png");
}

.face .icon-top.icon-etch {
  top: 2px;
  left:calc(10% + .15rem + 2px);
}

.face .icon-bottom {
  bottom: 0px;
  transform: rotate(180deg);
}

.face .icon-bottom.icon-etch {
  bottom: -2px;
  left:calc(10% + .15rem + 2px);
}
/* This represents the final rotation in 3d of the die */
/* We use an even and an odd roll to switch between the transformations so rolling face 3 twice in a row still results in an animation */
.even-roll[data-roll="1"] {
  transform: rotate(360deg) rotateX(360deg) rotateY(360deg) rotateZ(300deg);
}
.even-roll[data-roll="2"] {
  transform: rotate(360deg) rotateX(450deg) rotateY(340deg) rotateZ(360deg);
}
.even-roll[data-roll="3"] {
  transform: rotate(650deg) rotateX(360deg) rotateY(270deg) rotateZ(360deg);
}
.even-roll[data-roll="4"] {
  transform: rotate(660deg) rotateX(360deg) rotateY(450deg) rotateZ(360deg);
}
.even-roll[data-roll="5"] {
  transform: rotate(360deg) rotateX(270deg) rotateY(390deg) rotateZ(360deg);
}
.even-roll[data-roll="6"] {
  transform: rotate(360deg) rotateX(360deg) rotateY(540deg) rotateZ(250deg);
}
.odd-roll[data-roll="1"] {
  transform: rotate(-360deg) rotateX(-360deg) rotateY(-360deg) rotateZ(-60deg);
}
.odd-roll[data-roll="2"] {
  transform: rotate(-360deg) rotateX(-270deg) rotateY(-390deg) rotateZ(-360deg);
}
.odd-roll[data-roll="3"] {
  transform: rotate(-400deg) rotateX(-360deg) rotateY(-450deg) rotateZ(-360deg);
}
.odd-roll[data-roll="4"] {
  transform: rotate(-390deg) rotateX(-360deg) rotateY(-630deg) rotateZ(-360deg);
}
.odd-roll[data-roll="5"] {
  transform: rotate(-360deg) rotateX(-450deg) rotateY(-670deg) rotateZ(-360deg);
}
.odd-roll[data-roll="6"] {
  transform: rotate(-360deg) rotateX(-360deg) rotateY(-540deg) rotateZ(-140deg);
}

/* This represents the sides of the dice being bent around each other */
[data-side="1"] {
  transform: rotate3d(0, 0, 0, 90deg) translateZ(3.5rem);
}
[data-side="2"] {
  transform: rotate3d(-1, 0, 0, 90deg) translateZ(3.5rem);
}
[data-side="3"] {
  transform: rotate3d(0, 1, 0, 90deg) translateZ(3.5rem);
}
[data-side="4"] {
  transform: rotate3d(0, -1, 0, 90deg) translateZ(3.5rem);
}
[data-side="5"] {
  transform: rotate3d(1, 0, 0, 90deg) translateZ(3.5rem);
}
[data-side="6"] {
  transform: rotate3d(1, 0, 0, 180deg) translateZ(3.5rem);
}

@media (min-width: 900px) {
  .dice {
    perspective: 1300px;
  }
}

@media (max-width: 720px) {
  .die-list {
    height: 3rem;
    width: 3rem;
    line-height: 4.25rem;
  }

  [data-side="1"] {
    transform: rotate3d(0, 0, 0, 90deg) translateZ(2rem);
  }
  [data-side="2"] {
    transform: rotate3d(-1, 0, 0, 90deg) translateZ(2rem);
  }
  [data-side="3"] {
    transform: rotate3d(0, 1, 0, 90deg) translateZ(2rem);
  }
  [data-side="4"] {
    transform: rotate3d(0, -1, 0, 90deg) translateZ(2rem);
  }
  [data-side="5"] {
    transform: rotate3d(1, 0, 0, 90deg) translateZ(2rem);
  }
  [data-side="6"] {
    transform: rotate3d(1, 0, 0, 180deg) translateZ(2rem);
  }
  
  .die-item {
    padding: .5rem;
    font-size:1.2rem;
  }
  
  .even-roll.position0 {
    left: 4rem;
    top: 3rem;
  }
  .even-roll.position1 {
    left: 12rem;
    top: 4rem;
  }
  .even-roll.position2 {
    left: 3rem;
    top: 11rem;
  }
  .even-roll.position3 {
    left: 12rem;
    top: 13rem;
  }

  .odd-roll.position0 {
    left: 4rem;
    top: 5rem;
  }
  .odd-roll.position1 {
    left: 12rem;
    top: 4rem;
  }
  .odd-roll.position2 {
    left: 5rem;
    top: 12rem;
  }
  .odd-roll.position3 {
    left: 12rem;
    top: 11rem;
  }
  
  .dice {
    padding: 10%;
    height:20rem;
    width:80%;
  }
}
